// author锛歫iana
// time:2024-06-12
// desc:鏈〉闈负鏂逛究蹇€熸瀯寤簐ue鏂囦欢妯℃澘鍩虹椤甸潰鎼缓锛屽湪娆″熀纭€涓婂揩閫熸惌寤篭r

<template>
	<div class="productCenter">
		<menuNav :bgClass="true"></menuNav>
		<div class="topImg">
			<el-carousel height="115vh" arrow='never'>
				<el-carousel-item v-for="item,index in imageList" :key="index">
					<img :src="item.url" alt="">
					<div class="jieshi">
						<div class="titleOne">閲戣瀺浜у搧</div>
						<div class="titleTwo">{{item.name}}</div>
						<div class="infoBox">
							{{item.summary}}
						</div>
						<div @click="goInfo(item,index)">
							<el-button plain size="mini">鏌ョ湅璇︽儏 <i class="el-icon-right"></i></el-button>
						</div>
					</div>
				</el-carousel-item>
			</el-carousel>
		</div>
		<!-- 渚ц竟瀵艰埅 -->
		<div class="rightBox">
			<rightNav @goBack="goBack"></rightNav>
		</div>
		<!-- 搴曢儴 -->
		<footerIndex></footerIndex>
	</div>
</template>

<script>
import menuNav  from '../components/nav/menuNav.vue'
import rightNav  from '../components/nav/rightNav.vue'
import footerIndex from '../components/footer/footerIndex.vue'
import {resourceList,resourcePage} from '../request/service'
export default {
	name: 'productCenter',
	components: {
		menuNav,footerIndex,rightNav
	},
	props:{
		
	},
	data() {
		return {
			imageList:[],
		}
	},
	computed: {

	},
	methods: {
		//鍥炲埌椤堕儴
		goBack(){
			window.scrollTo(0,0)
		},
		getImage(){
			resourceList({typeCode:'cpzx_002'}).then((res) => {
				if(res.code == 101){
					this.imageList = res.data
				}else{
					this.$Message.error(res.message);
				}
			})
		},
		goInfo(item){
			this.$jump('/InfoDetail?id='+item.id)
		}
	},

	mounted() {
		this.getImage()
	},

	watch: {

	}
}
</script>

<style lang="less" scoped>
.productCenter{
	.rightBox{
		position: fixed;
		right: 10px;
		top: 50vh;
		z-index: 9999;
	}
	.topImg{
		width: 100%;
		height:115vh;
		margin-top: 50px;
		img{
			width: 100%;
			// height: 100%;
		}
	}
	.box1200{
		width: 1200PX;
		margin: 0 auto;
	}
	.marginTop50{
		margin-top: 50px;
	}
	.jieshi{
		position: fixed;
		top: 26%;
		left: 30%;
		color: #333333;
	}
	.titleOne{
		font-size: 30px;
		margin-bottom: 30px;
		font-weight: bold;
	}
	.titleTwo{
		font-size: 20px;
		margin-bottom: 30px;
	}
	.infoBox{
		font-size: 12px;
		width: 300px;
		margin-bottom: 30px;
	}
}
</style>
<style lang="less">
.productCenter{
	.el-button--mini{
		font-size: 0.25rem;
	}
	.el-carousel__button{
		background-color: #907e67;
	}
}
</style>
